export enum TaxesField {
  TaxRate = 'taxRate',
  TaxJurisdiction = 'taxJurisdiction',
}

/**
 * This is the type of taxes form.
 */
export type ITaxesForm = {
  [TaxesField.TaxRate]: number;
  [TaxesField.TaxJurisdiction]: string;
};
