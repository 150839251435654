/**
 * Customer with basic properties.
 * E.g. UID and email for listing.
 */
export type BasicCustomer = {
  uid: string;
  email: string;
  operatingName: string;
  legalBusinessName: string;
};
