import { DbCustomerProfile, DbCustomerProfileField, DbField } from '@uvac-apps/db-models';

import { BasicCustomer } from '@lib/firestore/Customer';

export type IExtendedCustomerProfile = DbCustomerProfile & { customer: BasicCustomer };

export type ICreateCustomerProfile = Pick<
  DbCustomerProfile,
  | DbCustomerProfileField.CustomerId
  | DbCustomerProfileField.VendorId
  | DbCustomerProfileField.DiscountRate
>;

export type IEditCustomerProfile = Pick<
  DbCustomerProfile,
  DbField.Id | DbCustomerProfileField.DiscountRate
>;
