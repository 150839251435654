import {
  DbField,
  DbVehicle,
  DbVehicleAvailabilityField,
  DbVehicleField,
  DbVehiclePricingField,
} from '@uvac-apps/db-models';

/**
 * Model for creating vehicle.
 */
export type ICreateVehicle = Pick<
  DbVehicle,
  | DbVehicleField.Name
  | DbVehicleField.Description
  | DbVehicleField.DispatchLocation
  | DbVehicleField.VendorId
  | DbVehiclePricingField.HourlyRate
  | DbVehiclePricingField.AdditionalPrice
>;

/**
 * Model for updating vehicle.
 */
export type IEditVehicle = Pick<
  DbVehicle,
  | DbField.Id
  | DbVehicleField.Description
  | DbVehicleField.Name
  | DbVehicleField.DispatchLocation
  | DbVehiclePricingField.HourlyRate
  | DbVehiclePricingField.AdditionalPrice
  | DbVehicleAvailabilityField.UnavailableDate
>;
