export interface AddressAutocompleteResult {
  description: string;
  matched_substrings: { length: number; offset: number }[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: { length: number; offset: number }[];
    secondary_text: string;
  };
  terms: { offset: number; value: string }[];
  types: string[];
}

export interface AddressAutocompleteGeoResult {
  streetAddress?: string;
  streetNumber?: string;
  country?: string;
  countryCode?: string; // Short code for country (eg. US for United States)
  postalCode?: string;
  state?: string;
  stateCode?: string; // Short code for state (eg. CA for California)
  city?: string;
}

export type AddressGeoResultType =
  | 'street_number'
  | 'route'
  | 'country'
  | 'postal_code'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'locality';
