import {
  DbField,
  DbJob,
  DbJobNoteField,
  JobStatus,
  RateMode,
  UserType,
} from '@uvac-apps/db-models';

export type IAddJobNote = {
  [DbField.Id]: string;
  [DbJobNoteField.Text]: string;
  [DbJobNoteField.CreatorType]: UserType;
};

export type ICreateJob = Omit<
  DbJob,
  | 'id'
  | 'status'
  | 'timestamps'
  | 'customer_id'
  | 'notes'
  | 'vendor_order_id'
  | 'invoice'
  | 'vendor_id'
> & {
  rateMode: RateMode;
};

export type IEditTaxRate = {
  id: string; // Job ID.
  taxRate: number;
  taxJurisdiction: string;
};

export type MyJobsOptions = {
  status: JobStatus[];
};
